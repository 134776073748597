<footer class="footer stick-down">
  <div id="footer-widgets">
    <br>
    <div class="d-flex justify-content-center">
      <a href="https://www.microting.com/terms-of-service" target="_blank">
        {{'Terms of Service' | translate}}
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <a href="https://www.microting.com/privacy-policy" target="_blank">
        {{'Privacy Policy' | translate}}
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <a href="https://www.microting.com/data-protection-terms" target="_blank">
        {{'Data Protection Terms (GDPR)' | translate}}
      </a>
      <b></b>
    </div>
  </div>
  <div class="footer-copyright text-center p-2 copyright-section">
    <div>© Microting A/S 2007 - {{date.getFullYear()}} <div class="footer-version">eForm Backend v{{version}}</div> </div>
  </div>
</footer>
