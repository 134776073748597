export const itIT = {
  Settings: 'Impostazioni',
  Save: 'Salva',
  'Login Page': 'Pagina di login',
  Reset: 'Ripristina',
  'Main Text': 'Testo principale',
  'Secondary Text': 'Testo secondario',
  Image: 'Immagine',
  'Recommended parameters': 'Parametri consigliati',
  Resolution: 'Risoluzione',
  Proportions: 'Proporzioni',
  'Site header': 'Intestazione del sito',
  Logo: 'Logo',
  'SMTP Information': 'Informazioni SMTP',
  Host: 'Ospite',
  Port: 'Porta',
  Login: 'Login',
  Password: 'Parola d&#39;ordine',
  'Forgot password': 'Ha dimenticato la password',
  'Restore password': 'Ripristina la password',
  'Reset admin password': 'Reimposta la password dell&#39;amministratore',
  'Back to login': 'Torna al login',
  'Reset password': 'Resetta la password',
  Approved: 'Approvato',
  Review: 'Revisione',
  'Not approved': 'Non approvato',
  'Updating cases, please wait': 'Aggiornamento dei casi, attendere',
  Search: 'Ricerca',
  done_at: 'Il modulo elettronico è terminato alle',
  created_at: 'Dati accessibili all&#39;indirizzo',
  worker_name: 'Modulo elettronico eseguito da',
  'Edit Case': 'Modifica caso',
  'Delete Case': 'Elimina caso',
  Download: 'Scaricamento',
  'Are you sure you want to delete this case': 'Sei sicuro di voler eliminare questo caso?',
  'Accessible at': 'Accessibile a',
  'Finished at': 'Finito alle',
  'Done by': 'Fatto da',
  OK: 'OK',
  Cancel: 'Annulla',
  'Edit Searchable item': 'Modifica elemento ricercabile',
  'Delete Searchable item': 'Elimina elemento ricercabile',
  'Edit searchable element': 'Modifica elemento ricercabile',
  Name: 'Nome',
  Ok: 'OK',
  'Import options (max 100 entries)': 'Opzioni di importazione (max 100 voci)',
  Import: 'Importare',
  'My eForms': 'I miei moduli elettronici',
  'Create eForm': 'Crea modulo elettronico',
  'New eForm': 'Nuovo modulo elettronico',
  'New eForm from XML': 'Nuovo modulo elettronico da XML',
  Label: 'Etichetta',
  Tags: 'Tag',
  CreatedAt: 'Creato a',
  'eForm Name': 'Nome modulo elettronico',
  Pairing: 'accoppiamento',
  Actions: 'Azioni',
  'Edit tags': 'Modificare i tag',
  'Click to edit eForm pairing with user(s)': 'Fare clic per modificare l&#39;associazione del modulo elettronico con gli utenti',
  'Edit pairing': 'Modifica abbinamento',
  'Click to pair eForm with user(s)': 'Fare clic per associare l&#39;eForm agli utenti',
  'Pair eForm': 'Associa eForm',
  'Show cases': 'Vetrine',
  'Edit columns': 'Modifica colonne',
  'Download CSV': 'Scarica CSV',
  'Delete eForm': 'Elimina modulo elettronico',
  'Upload ZIP archive': 'Carica l&#39;archivio ZIP',
  'Upload template ZIP': 'Carica il modello ZIP',
  'Uploading progress': 'Caricamento progressi',
  'Save eForm': 'Salva modulo elettronico',
  'Device user': 'Utente del dispositivo',
  'Check to pair': 'Seleziona per accoppiare',
  'Save pairing': 'Salva abbinamento',
  'Select columns for displaying in Cases': 'Seleziona le colonne da visualizzare in Casi',
  Field: 'Campo',
  'Are you sure you want to delete': 'Sei sicuro di voler eliminare',
  'Creation Date': 'Data di creazione',
  'Select tags for template': 'Seleziona i tag per il modello',
  'Template tags': 'Tag modello',
  'New tag': 'Nuova etichetta',
  'Tag list': 'Elenco dei tag',
  'Add field': 'Aggiungi campo',
  'Add sub eForm': 'Aggiungi modulo elettronico secondario',
  'Edit sub eForm': 'Modifica modulo elettronico secondario',
  'New sub eForm': 'Nuovo modulo elettronico secondario',
  'New field': 'Nuovo campo',
  'Field is not complete': 'Il campo non è completo',
  'Add new nested field': 'Aggiungi campo al gruppo',
  'Copy field': 'Copia campo',
  'Edit field': 'Modifica campo',
  'Delete field': 'Elimina campo',
  'Expand field': 'Mostra i campi nel gruppo',
  'Collapse field': 'Nascondi i campi nel gruppo',
  Mandatory: 'Obbligatorio',
  Type: 'Tipo',
  'Are you sure you want to delete field': 'Sei sicuro di voler eliminare il campo?',
  'eForm is not complete': 'Il modulo elettronico non è completo',
  'Edit eForm': 'Modifica modulo elettronico',
  'New nested field': 'Nuovo campo nidificato',
  Delete: 'Eliminare',
  'XML from eForm Builder': 'XML da eForm Builder',
  'Paste XML here': 'Incolla qui l&#39;XML',
  'Creation date': 'Data di creazione',
  'Searchable lists': 'Elenchi ricercabili',
  'Searchable list': 'Elenco ricercabile',
  'Create new searchable list': 'Crea un nuovo elenco ricercabile',
  'Edit site': 'Modifica sito',
  'Selectable lists': 'Elenchi selezionabili',
  'Selectable list': 'Elenco selezionabile',
  'Create new selectable list': 'Crea un nuovo elenco selezionabile',
  'Terms of Service': 'Termini di servizio',
  'Privacy Policy': 'politica sulla riservatezza',
  'Data Protection Terms (GDPR)': 'Termini sulla protezione dei dati (GDPR)',
  'Save password': 'Salva la password',
  'Edit Device User': 'Modifica utente dispositivo',
  'First name': 'Nome di battesimo',
  'Last name': 'Cognome',
  'Device Users': 'Utenti del dispositivo',
  'Create Device User': 'Crea utente dispositivo',
  'New Device User': 'Nuovo utente del dispositivo',
  'Site ID': 'ID del sito',
  'Device ID': 'ID del dispositivo',
  Customer: 'Cliente',
  'Customer no & OTP': 'N. cliente e OTP',
  'New OTP': 'Nuova OTP',
  'Delete Device User': 'Elimina utente dispositivo',
  WARNING: 'AVVERTIMENTO',
  'When pressing OK, you will deactivate the current device paired with this location': 'Premendo OK, disattiverai il dispositivo corrente associato a questa posizione',
  'If there is data on the current device, this data will be lost after pressing OK': 'Se sono presenti dati sul dispositivo corrente, questi dati andranno persi dopo aver premuto OK',
  Edit: 'Modificare',
  Sites: 'Siti',
  'Updated at': 'Aggiornato a',
  'Are you sure you want to delete the site? The action can not be undone': 'Sei sicuro di voler eliminare il sito? L&#39;azione non può essere annullata',
  Previous: 'Precedente',
  Next: 'Prossimo',
  Units: 'Unità',
  'Get OTP code': 'Ottieni il codice OTP',
  'Activate OTP code for': 'Attiva il codice OTP per',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Premendo OK, disattiverai l&#39;unità corrente associata a questa posizione',
  'You will se the new OTP code once you click OK': 'Vedrai il nuovo codice OTP dopo aver fatto clic su OK',
  Workers: 'Lavoratori',
  'Create Worker': 'Crea lavoratore',
  'Edit Worker': 'Modifica lavoratore',
  'Delete Worker': 'Elimina lavoratore',
  'Are you sure you want to delete the worker': 'Sei sicuro di voler eliminare il lavoratore?',
  'Select Worker': 'Seleziona Lavoratore',
  'Save Worker': 'Salva lavoratore',
  Advanced: 'Ammin',
  'Application Settings': 'Impostazioni dell&#39;applicazione',
  'User management': 'Gestione utenti',
  'Change password': 'Cambiare la password',
  Logout: 'Disconnettersi',
  'Profile settings': 'Impostazioni del profilo',
  Timezone: 'Fuso orario',
  Language: 'Lingua',
  'Force users to use 2-f-auth': 'Forza gli utenti a utilizzare 2-f-auth',
  'Old password': 'Vecchia password',
  'New password': 'Nuova password',
  'New password confirmation': 'Conferma nuova password (min 6 caratteri)',
  'Confirm new password': 'Conferma nuova password (min 6 caratteri)',
  'Enter new password': 'Inserisci la nuova password (minimo 6 caratteri)',
  'Enter old password': 'Inserisci la vecchia password',
  'New User': 'Nuovo utente',
  'Edit User': 'Modifica utente',
  'Left empty if want to keep old': 'Lasciato vuoto se si vuole mantenere il vecchio',
  Username: 'Nome utente',
  Email: 'E-mail',
  Status: 'Stato',
  'change will logout you from the application': 'la modifica ti disconnetterà dall&#39;applicazione',
  'Remove google authenticator secret key': 'Rimuovi la chiave segreta dell&#39;autenticatore Google',
  'Remove PSK': 'Rimuovi PSK',
  'Full Name': 'Nome e cognome',
  'Delete user': 'Elimina utente',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Apri Google Authenticator e aggiungi l&#39;applicazione Eform scansionando il codice QR a destra',
  'Enter the 6 digit code that Google Authenticator generates': 'Inserisci il codice di 6 cifre generato da Google Authenticator',
  General: 'Generale',
  Find: 'Trovare',
  'Nothing found': 'Non abbiamo trovato nulla',
  'Type in search box to see results': 'Digita nella casella di ricerca per visualizzare i risultati',
  'Select tags for filter': 'Seleziona i tag per il filtro',
  'All selected': 'Tutto selezionato',
  'Showing to of records': 'Visualizzazione da {{currentPageAmount}} a {{currentPage}} di {{total}} record',
  'Google Authenticator description': '<p>Chiave segreta tra l&#39;applicazione <strong>Eform</strong> e <strong>Google Authenticator</strong> . Tienilo al sicuro e non dirlo a nessuno.</p><p> Dovresti rimuovere questa chiave se <strong>Google Authenticator</strong> è stato reinstallato o se la chiave è stata rimossa</p>',
  'New OTP for': 'Nuova OTP per',
  Loading: 'Caricamento',
  Pending: 'In attesa di',
  Upload: 'Caricamento',
  Role: 'Ruolo',
  'New list': 'Nuovo elenco',
  'Name is required': 'Il nome è obbligatorio',
  'Edit searchable list': 'Modifica elenco ricercabile',
  'eForm Pairing': 'Associazione eForm',
  'Tag for removal': 'Etichetta per la rimozione',
  'New tags': 'Nuovi tag',
  'Rotate on 90': 'Ruota di 90',
  'Delete picture': 'Elimina l&#39;immagine',
  'Open location': 'Posizione aperta',
  'File has been uploaded successfully': 'Il file è stato caricato con successo',
  'Error while uploading file': 'Errore durante il caricamento del file',
  Customers: 'Clienti',
  'Do you want to leave this page': 'Vuoi lasciare questa pagina?',
  'If you leave before saving your changes will be lost': 'Se esci prima di salvare le modifiche andranno perse',
  'Save & Leave': 'Salva e lascia',
  Cases: 'Casi',
  Calendar: 'Calendario',
  'Case management': 'Gestione del caso',
  Security: 'Sicurezza',
  'Security Groups': 'Gruppi di sicurezza',
  GroupName: 'Nome del gruppo',
  UserAmount: 'Importo utenti',
  'Edit Group': 'Modifica gruppo',
  Permissions: 'Autorizzazioni',
  'Delete Group': 'Elimina gruppo',
  'New Group': 'Nuovo gruppo',
  'Create Group': 'Creare un gruppo',
  Group: 'Gruppo',
  'Create Security Group': 'Crea gruppo di sicurezza',
  'Group Name': 'Nome del gruppo',
  'Select User': 'Seleziona Utente',
  'Bind to Group': 'Associa al gruppo',
  Create: 'Creare',
  Close: 'Vicino',
  'Remove user from group': 'Rimuovi l&#39;utente dal gruppo',
  UserManagement: 'Gestione utenti',
  Update: 'Aggiornamento',
  Read: 'Leggere',
  'General Permissions': 'Autorizzazioni generali',
  Column: 'Colonna',
  'Eform Permissions': 'Eform Permessi',
  'Add Eform to Group': 'Aggiungi Eform al gruppo',
  Forbidden: 'Vietato',
  'Add user to group': 'Aggiungi utente al gruppo',
  'Update columns': 'Aggiorna colonne',
  'Download XML': 'Scarica XML',
  'Upload ZIP': 'Carica CAP',
  'Pairing read': 'Lettura dell&#39;abbinamento',
  'Pairing update': 'Aggiornamento dell&#39;accoppiamento',
  'Read tags': 'Leggi i tag',
  'Update tags': 'Aggiorna i tag',
  'Get CSV': 'Ottieni CSV',
  'Get DOCX': 'Ottieni DOCX',
  'Get PDF': 'Ottieni PDF',
  'Get PPTX': 'Ottieni PPTX',
  'Cases read': 'Casi letti',
  'Case read': 'Caso letto',
  'Case update': 'Aggiornamento del caso',
  'Case delete': 'Eliminazione del caso',
  'Bind Eform': 'Associa Eform',
  'Read Jasper report': 'Leggi il rapporto di Jasper',
  'Update Jasper report': 'Aggiorna il rapporto Jasper',
  Display: 'Schermo',
  Enabled: 'Abilitato',
  Disabled: 'Disabilitato',
  'Plugins Settings': 'Plugin',
  PluginsSettings: 'Plugin',
  'Edit Settings': 'Modificare le impostazioni',
  'Connection String': 'Stringa di connessione',
  Version: 'Versione',
  'Header Picture': 'Immagine dell&#39;intestazione',
  Preview: 'Anteprima',
  Commentary: 'Commento',
  Text: 'Testo',
  'Selected Value': 'Valore selezionato',
  'PDF Link': 'Collegamento PDF',
  Information: 'Informazione',
  Duration: 'Durata',
  Audio: 'Audio',
  'Are you sure you want to install plugin': 'Sei sicuro di voler installare il plugin',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'L&#39;operazione potrebbe richiedere fino a 10 minuti e riscrivere la versione corrente del plug-in se è già presente nel sistema',
  Installation: 'Installazione',
  Install: 'Installare',
  Marketplace: 'Mercato',
  'Plugins marketplace': 'Mercato dei plugin',
  'Plugins Management': 'Gestione dei plugin',
  'Warning: you will be logged out when status is changed': 'Attenzione: verrai disconnesso al cambio di stato',
  'Select tags for site': 'Seleziona i tag per il sito',
  'Add new': 'Aggiungere nuova',
  'Email Recipients': 'Destinatari e-mail',
  'Manage tags': 'Gestisci i tag',
  'Add Recipients': 'Aggiungi destinatari',
  'Edit email recipient': 'Modifica destinatario email',
  'Delete email recipient': 'Elimina il destinatario dell&#39;e-mail',
  'No email recipients found': 'Nessun destinatario email trovato',
  'New Email recipients': 'Nuovi destinatari e-mail',
  'Email addresses': 'Indirizzi email',
  'Edit tag name': 'Modifica il nome dell&#39;etichetta',
  'Tags management': 'Gestione dei tag',
  'Email/name text area should have at least one email/name pair': 'L&#39;area di testo email/nome deve avere almeno una coppia email/nome',
  'SendGrid Key': 'Chiave SendGrid',
  'SendGrid Information': 'Informazioni SendGrid',
  'Saved locally': 'Salvato localmente',
  'Ready for server': 'Pronto per il server',
  'Received by server': 'Ricevuto dal server',
  'Retrieved by device': 'Recuperato dal dispositivo',
  Completed: 'Completato',
  'Case posts': 'Post di casi',
  'No posts found': 'Nessun post trovato',
  'eForm conducted by': 'eForm condotto da',
  'New post': 'Nuovo messaggio',
  'Created by': 'Creato da',
  To: 'A',
  Subject: 'Soggetto',
  'Email text': 'testo dell&#39;e-mail',
  'Link to case': 'Collegamento al caso',
  'Attach PDF': 'Allega PDF',
  'Post created by': 'Post creato da',
  'Post sent to': 'Posta inviata a',
  Location: 'Posizione',
  'InSight Version': 'Versione Insight',
  'eForm Version': 'Versione del modulo elettronico',
  'Sync delay': 'Ritardo di sincronizzazione',
  'Activate sync delay': 'Attiva il ritardo di sincronizzazione',
  'Sync dialog': 'Finestra di dialogo di sincronizzazione',
  'Activate sync dialog': 'Attiva la finestra di dialogo di sincronizzazione',
  Push: 'Spingere',
  'Activate push': 'Attiva la spinta',
  'Move unit': 'Spostare l&#39;unità',
  'Create unit': 'Crea unità',
  'Select location': 'Seleziona la posizione',
  Move: 'Mossa',
  'Current tags': 'Tag attuali',
  'Edit selectable element': 'Modifica elemento selezionabile',
  'Edit selectable list': 'Modifica elenco selezionabile',
  Folders: 'Cartelle',
  Description: 'Descrizione',
  'Create folder': 'Creare una cartella',
  'Storage information': 'Informazioni sull&#39;archiviazione',
  'Swift enabled': 'Swift abilitato',
  'User name': 'Nome utente',
  'Swift endpoint': 'Punto finale rapido',
  'Keystone endpoint': 'Punto finale chiave di volta',
  'S3 enabled': 'S3 abilitato',
  'S3 accessKey': 'Chiave di accesso S3',
  'S3 secretAccessKey': 'Chiave di accesso segreta S3',
  'S3 endpoint': 'Punto terminale S3',
  'S3 bucket name': 'Nome del bucket S3',
  'File location picture': 'Immagine della posizione del file',
  'File location PDF': 'Posizione del file PDF',
  'File location Jasper report files': 'Posizione dei file File di report Jasper',
  'Http server address': 'Indirizzo del server HTTP',
  'SDK information': 'Informazioni sull&#39;SDK',
  'Customer no': 'Cliente n',
  Management: 'Gestione',
  'Update security group': 'Aggiorna il gruppo di sicurezza',
  'eForms permissions': 'autorizzazioni per i moduli elettronici',
  'Menu editor': 'Redattore di menu',
  'Edit menu entry': 'Modifica voce di menu',
  Link: 'Collegamento',
  'Are you sure you want to delete menu entry': 'Sei sicuro di voler eliminare la voce del menu?',
  'New dropdown': 'Nuovo menu a discesa',
  Dropdown: 'Cadere in picchiata',
  'Security groups': 'Gruppi di sicurezza',
  Custom: 'Costume',
  Add: 'Aggiungere',
  'Generate report': 'Genera rapporto {{value}}',
  'Select date range': 'Seleziona l&#39;intervallo di date',
  'Edit headers': 'Modifica intestazioni',
  'Text (TextSingleLine)': 'Testo a riga singola',
  Generate: 'Generare rapporto',
  CheckBox: 'Casella di controllo',
  SingleSelect: 'Cadere in picchiata',
  Comment: 'Testo',
  Number: 'Numerico',
  Picture: 'Immagine',
  None: 'Casella informativa',
  Date: 'Data',
  SaveButton: 'Salva',
  ShowPDF: 'PDF',
  FieldGroup: 'Gruppo',
  NumberStepper: 'Passo passo',
  Signature: 'Firma',
  Timer: 'InizioStopTime',
  EntitySearch: 'Ricerca a discesa (le mie liste)',
  EntitySelect: 'A discesa (le mie liste)',
  MultiSelect: 'Selezione multipla',
  'Save eForm.': 'Salva modulo elettronico',
  'Server date': 'Data del server',
  'eForm Pairing for': 'Associazione eForm per',
  'Select Site': 'Seleziona Sito',
  Select: 'Selezionare',
  Employee: 'Dipendente',
  Site: 'Luogo',
  'Selected language': 'Lingua selezionata',
  'New folder': 'Nuova cartella',
  Back: 'Indietro',
  Danish: 'danese',
  English: 'Inglese',
  German: 'Tedesco',
  Spanish: 'spagnolo',
  French: 'francese',
  Italian: 'Italiano',
  Polish: 'Polacco',
  Portuguese: 'portoghese',
  'Brazilian Portuguese': 'Portoghese Brasiliano',
  Ukrainian: 'ucraino',
  Swedish: 'svedese',
  Norwegian: 'norvegese',
  Dutch: 'Olandese',
  Finish: 'Fine',
  'Done at': 'Fatto a',
  'Select date': 'Seleziona la data',
  Value: 'Valore',
  'Please fill out this field': 'Perfavore compila questo campo',
  'Submitted date': 'Data di invio',
  Languages: 'Le lingue',
  'Uploading progress:': 'Avanzamento caricamento:',
  'Choose file': 'Scegli il file',
  'PAGINATOR.ITEMS_PER_PAGE': 'oggetti per pagina',
  'PAGINATOR.NEXT_PAGE': 'Pagina successiva',
  'PAGINATOR.PREVIOUS_PAGE': 'Pagina precedente',
  'PAGINATOR.OF_LABEL': 'Di',
  'PAGINATOR.RANGE_PAGE_LABEL': 'Di',
  'PAGINATOR.PAGE': 'Pagina',
  'No data in selected period': 'Nessun dato nel periodo selezionato',
  'Dark theme': 'Tema scuro',
  Formats: 'Formati',
  'Save settings': 'Salva le impostazioni',
  'No records found': 'Nessun record trovato',
  'Time zone': 'Fuso orario',
  'Set password': 'Impostare la password',
  'Edit user': 'Modifica utente',
  'Create user': 'Creare un utente',
  'Done button enabled': 'Pulsante Fine abilitato',
};
