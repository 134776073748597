<mat-card class="d-flex flex-row align-items-center mb-3 p-3">
  <div class="d-flex flex-column">
    <h2 style="margin: 0">
      {{
      forceStaticTitle
        ? title
        : (appMenuStateService.getTitleByUrl(this.href) | translate)
      }}
      <small *ngIf="subtitle" style="font-size: 1rem">({{ subtitle | translate }})</small>
    </h2>
    <ul class="eform-breadcrumb" *ngIf="breadcrumbs">
      <li
        [class]="isLast ? 'active' : ''"
        *ngFor="let breadcrumbItem of breadcrumbs; last as isLast"
      >
        <a *ngIf="breadcrumbItem.href" [routerLink]="breadcrumbItem.href">{{
          breadcrumbItem.name
          }}</a>
        <div *ngIf="!breadcrumbItem.href || isLast">{{ breadcrumbItem.name }}</div>
      </li>
    </ul>
  </div>
  <span class="spacer"></span>
  <ng-content></ng-content>
</mat-card>
