export enum EformFieldTypesEnum {
  Text = 1,
  Number,
  None,
  CheckBox,
  Picture,
  Audio,
  Movie,
  SingleSelect,
  Comment,
  MultiSelect,
  Date,
  Signature,
  Timer,
  EntitySearch,
  EntitySelect,
  ShowPdf,
  FieldGroup,
  SaveButton,
  NumberStepper,
}
